import React, { useState } from 'react';
import { FiPlus, FiUpload, FiTrash, FiEdit } from 'react-icons/fi';

const CallAutomation = () => {
  const [campaignType, setCampaignType] = useState('ai');
  const [preRecordedFiles, setPreRecordedFiles] = useState([]);
  const [aiSalesPitch, setAiSalesPitch] = useState('');
  const [callFlow, setCallFlow] = useState([]);
  const [currentStep, setCurrentStep] = useState('');
  const [aiModel, setAiModel] = useState('');
  const [selectedVoice, setSelectedVoice] = useState('');
  const [savedAutomations, setSavedAutomations] = useState([]);
  const [editIndex, setEditIndex] = useState(null); // Track index of editing automation

  const aiModels = ['ChatGPT', 'Facebook LLaMA', 'OpenAI GPT-4', 'Custom Model'];
  const voiceOptions = ['Male', 'Female', 'UK Accent', 'US Accent'];

  const handleCampaignType = (type) => {
    setCampaignType(type);
    setPreRecordedFiles([]);
    setAiSalesPitch('');
    setCallFlow([]);
    setEditIndex(null); // Reset editing mode
  };

  const handlePreRecordedFileChange = (e) => {
    const files = Array.from(e.target.files);
    setPreRecordedFiles(files);
  };

  const handleAddStep = () => {
    if (currentStep) {
      setCallFlow([...callFlow, currentStep]);
      setCurrentStep('');
    }
  };

  const handleDeleteStep = (index) => {
    const updatedFlow = callFlow.filter((_, i) => i !== index);
    setCallFlow(updatedFlow);
  };

  const handleSaveAutomation = () => {
    const newAutomation = {
      type: campaignType,
      aiModel,
      voice: selectedVoice,
      salesPitch: aiSalesPitch,
      preRecordedFiles,
      callFlow,
    };

    if (editIndex !== null) {
      const updatedAutomations = [...savedAutomations];
      updatedAutomations[editIndex] = newAutomation;
      setSavedAutomations(updatedAutomations);
      setEditIndex(null); // Exit edit mode
    } else {
      setSavedAutomations([...savedAutomations, newAutomation]);
    }

    setAiSalesPitch('');
    setCallFlow([]);
    setPreRecordedFiles([]);
    setSelectedVoice('');
    setAiModel('');
  };

  const handleEditAutomation = (index) => {
    const automationToEdit = savedAutomations[index];
    setCampaignType(automationToEdit.type);
    setAiSalesPitch(automationToEdit.salesPitch || '');
    setPreRecordedFiles(automationToEdit.preRecordedFiles || []);
    setCallFlow(automationToEdit.callFlow || []);
    setAiModel(automationToEdit.aiModel || '');
    setSelectedVoice(automationToEdit.voice || '');
    setEditIndex(index); // Set edit mode
  };

  const handleDeleteAutomation = (index) => {
    const updatedAutomations = savedAutomations.filter((_, i) => i !== index);
    setSavedAutomations(updatedAutomations);
  };

  return (
    <div className="p-6 flex flex-col md:flex-row md:space-x-6 space-y-6 md:space-y-0">
      {/* Left Section: Create Automation */}
      <div className="w-full md:w-1/2">
        {/* Campaign Type Selection */}
        <div className="mb-6 flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4">
          <button
            onClick={() => handleCampaignType('ai')}
            className={`px-4 py-2 rounded-md ${campaignType === 'ai' ? 'bg-black text-white' : 'border border-gray-300 text-gray-700'}`}
          >
            AI Automation
          </button>
          <button
            onClick={() => handleCampaignType('pre-recorded')}
            className={`px-4 py-2 rounded-md ${campaignType === 'pre-recorded' ? 'bg-black text-white' : 'border border-gray-300 text-gray-700'}`}
          >
            Pre-Recorded Voice Automation
          </button>
        </div>

        {/* AI Campaign Setup */}
        {campaignType === 'ai' && (
          <div className="border p-4 rounded-md mb-6">
            <h3 className="text-xl font-bold mb-4">AI Automation Setup</h3>

            <div className="mb-4">
              <label className="block mb-2 font-semibold">Choose AI Model</label>
              <select
                value={aiModel}
                onChange={(e) => setAiModel(e.target.value)}
                className="border border-gray-300 p-2 rounded-md w-full mb-4"
              >
                <option value="">Select AI Model</option>
                {aiModels.map((model, index) => (
                  <option key={index} value={model}>
                    {model}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label className="block mb-2 font-semibold">Choose AI Voice</label>
              <select
                value={selectedVoice}
                onChange={(e) => setSelectedVoice(e.target.value)}
                className="border border-gray-300 p-2 rounded-md w-full mb-4"
              >
                <option value="">Select Voice</option>
                {voiceOptions.map((voice, index) => (
                  <option key={index} value={voice}>
                    {voice}
                  </option>
                ))}
              </select>
            </div>

            <textarea
              placeholder="Enter the AI Sales Pitch"
              value={aiSalesPitch}
              onChange={(e) => setAiSalesPitch(e.target.value)}
              className="border border-gray-300 p-2 rounded-md w-full mb-4"
              rows={4}
            />
            <button
              onClick={handleSaveAutomation}
              className="bg-black text-white px-4 py-2 rounded-md w-full"
            >
              {editIndex !== null ? 'Update AI Automation' : 'Save AI Automation'}
            </button>
          </div>
        )}

        {/* Pre-Recorded Voice Automation Setup */}
        {campaignType === 'pre-recorded' && (
          <div className="border p-4 rounded-md mb-6">
            <h3 className="text-xl font-bold mb-4">Pre-Recorded Voice Automation Setup</h3>

            <div className="mb-4">
              <label className="block mb-2 font-semibold">Upload Pre-Recorded Voice Files:</label>
              <div className="border border-gray-300 rounded-md p-2 flex items-center justify-between">
                <input type="file" accept=".mp3,.wav" multiple onChange={handlePreRecordedFileChange} className="flex-grow border-none" />
                <FiUpload className="ml-4 text-gray-500" />
              </div>
            </div>

            {preRecordedFiles.length > 0 && (
              <div className="mb-4">
                <h4 className="font-bold mb-2">Uploaded Files:</h4>
                <ul className="list-disc ml-6">
                  {preRecordedFiles.map((file, index) => (
                    <li key={index} className="flex justify-between items-center">
                      {file.name}
                      <FiTrash className="ml-2 text-red-500 cursor-pointer" />
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="mb-4">
              <h4 className="font-bold mb-2">Define Call Flow (e.g., Multiple Options, Custom Text):</h4>
              <div className="flex mb-4 items-center space-x-2">
                <input
                  type="text"
                  placeholder="Enter call flow step (e.g., Custom Text or Option)"
                  value={currentStep}
                  onChange={(e) => setCurrentStep(e.target.value)}
                  className="border border-gray-300 p-2 rounded-md w-full"
                />
                <button
                  onClick={handleAddStep}
                  className="bg-black text-white px-2 py-1 rounded-md flex items-center justify-center space-x-1"
                  style={{ minWidth: '120px', whiteSpace: 'nowrap' }}
                >
                  <FiPlus />
                  <span>Add Step</span>
                </button>
              </div>

              {callFlow.length > 0 && (
                <ul className="list-disc ml-6">
                  {callFlow.map((step, index) => (
                    <li key={index} className="flex justify-between items-center">
                      {step}
                      <div className="flex space-x-2">
                        <FiEdit className="text-blue-500 cursor-pointer" />
                        <FiTrash className="ml-2 text-red-500 cursor-pointer" onClick={() => handleDeleteStep(index)} />
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <button
              onClick={handleSaveAutomation}
              className="bg-black text-white px-4 py-2 rounded-md w-full"
            >
              {editIndex !== null ? 'Update Pre-Recorded Automation' : 'Save Pre-Recorded Automation'}
            </button>
          </div>
        )}
      </div>

      {/* Right Section: Saved Automations */}
      <div className="w-full md:w-1/2">
        <div className="border p-4 rounded-md">
          <h3 className="text-xl font-bold mb-4">Saved Automations</h3>
          {savedAutomations.length > 0 ? (
            <ul>
              {savedAutomations.map((automation, index) => (
                <li key={index} className="mb-4 border-b pb-2">
                  <p><strong>Type:</strong> {automation.type === 'ai' ? 'AI' : 'Pre-Recorded Voice'}</p>
                  {automation.type === 'ai' && (
                    <>
                      <p><strong>AI Model:</strong> {automation.aiModel}</p>
                      <p><strong>Voice:</strong> {automation.voice}</p>
                      <p><strong>Sales Pitch:</strong> {automation.salesPitch}</p>
                    </>
                  )}
                  {automation.type === 'pre-recorded' && (
                    <>
                      <p><strong>Pre-Recorded Voice Files:</strong> {automation.preRecordedFiles.length} file(s)</p>
                      <p><strong>Call Flow:</strong> {automation.callFlow.join(', ')}</p>
                    </>
                  )}
                  <div className="flex justify-end space-x-2 mt-2">
                    <FiEdit className="text-blue-500 cursor-pointer" onClick={() => handleEditAutomation(index)} />
                    <FiTrash className="text-red-500 cursor-pointer" onClick={() => handleDeleteAutomation(index)} />
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>No saved automations yet.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CallAutomation;
