import React, { useState } from 'react';
import { FiPlus, FiMoreVertical } from 'react-icons/fi';
import { Menu, Transition } from '@headlessui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initialCampaigns = [
  {
    name: 'Campaign 1',
    group: 'Group A',
    totalClients: 100,
    called: 60,
    failed: 10,
    unanswered: 20,
    converted: 30,
    type: 'AI Campaign',
    status: 'active',
  },
  {
    name: 'Campaign 2',
    group: 'Group B',
    totalClients: 80,
    called: 50,
    failed: 5,
    unanswered: 15,
    converted: 20,
    type: 'Recorded Voice Campaign',
    status: 'paused',
  },
];

const Campaigns = ({ groups = ['Group A', 'Group B', 'Group C'] }) => {
  const [campaigns, setCampaigns] = useState(initialCampaigns);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCampaignName, setNewCampaignName] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [campaignType, setCampaignType] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [selectedStatus, setSelectedStatus] = useState('');

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setNewCampaignName('');
    setSelectedGroup('');
    setCampaignType('');
  };

  const createCampaign = () => {
    if (newCampaignName && selectedGroup && campaignType) {
      const newCampaign = {
        name: newCampaignName,
        group: selectedGroup,
        totalClients: Math.floor(Math.random() * 100) + 50,
        called: 0,
        failed: 0,
        unanswered: 0,
        converted: 0,
        type: campaignType,
        status: 'active',
      };
      setCampaigns([...campaigns, newCampaign]);
      closeModal();
    } else {
      alert('Please enter a campaign name, select a group, and choose a type.');
    }
  };

  const handleCampaignAction = (action, index) => {
    const updatedCampaigns = [...campaigns];
    if (action === 'delete') {
      updatedCampaigns.splice(index, 1);
    } else if (action === 'pause') {
      updatedCampaigns[index].status = 'paused';
    } else if (action === 'play') {
      updatedCampaigns[index].status = 'active';
    }
    setCampaigns(updatedCampaigns);
  };

  return (
    <div className="p-6">
      {/* Campaign Creation & Filters */}
      <div className="flex justify-between items-center mb-6 space-x-4">
        <div className="flex items-center space-x-2">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className="hidden md:block border border-gray-300 p-2 rounded-md"
            placeholderText="Filter by Date"
          />
          <select
            className="border border-gray-300 p-2 rounded-md"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            <option value="">All Status</option>
            <option value="active">Active Campaigns</option>
            <option value="paused">Paused Campaigns</option>
          </select>
        </div>

        {/* Create Campaign Button */}
        <button
          onClick={openModal}
          className="bg-black text-white px-4 py-2 rounded-md flex items-center space-x-2"
        >
          <FiPlus />
          <span>Create a Campaign</span>
        </button>
      </div>

      {/* Campaign List */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {campaigns
          .filter((campaign) => selectedStatus === '' || campaign.status === selectedStatus)
          .map((campaign, index) => (
            <div
              key={index}
              className="bg-white p-4 rounded-lg border border-gray-300 relative"
            >
              <h3 className="text-lg font-semibold mb-2">{campaign.name}</h3>
              <p className="text-sm text-gray-500 mb-2">Type: {campaign.type}</p>
              <p className="text-sm text-gray-500 mb-2">Group: {campaign.group}</p>

              <div className="space-y-2">
                <div>
                  <p className="text-sm">
                    Called: {campaign.called}/{campaign.totalClients} ({Math.round((campaign.called / campaign.totalClients) * 100)}%)
                  </p>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-blue-600 h-2 rounded-full"
                      style={{ width: `${(campaign.called / campaign.totalClients) * 100}%` }}
                    ></div>
                  </div>
                </div>
                <div>
                  <p className="text-sm">
                    Failed: {campaign.failed}/{campaign.totalClients} ({Math.round((campaign.failed / campaign.totalClients) * 100)}%)
                  </p>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-red-600 h-2 rounded-full"
                      style={{ width: `${(campaign.failed / campaign.totalClients) * 100}%` }}
                    ></div>
                  </div>
                </div>
                <div>
                  <p className="text-sm">
                    Unanswered: {campaign.unanswered}/{campaign.totalClients} ({Math.round((campaign.unanswered / campaign.totalClients) * 100)}%)
                  </p>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-yellow-500 h-2 rounded-full"
                      style={{ width: `${(campaign.unanswered / campaign.totalClients) * 100}%` }}
                    ></div>
                  </div>
                </div>
                <div>
                  <p className="text-sm">
                    Converted: {campaign.converted}/{campaign.totalClients} ({Math.round((campaign.converted / campaign.totalClients) * 100)}%)
                  </p>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-green-600 h-2 rounded-full"
                      style={{ width: `${(campaign.converted / campaign.totalClients) * 100}%` }}
                    ></div>
                  </div>
                </div>
              </div>

              {/* Dropdown with Actions */}
              <Menu as="div" className="absolute top-2 right-2">
                <Menu.Button>
                  <FiMoreVertical className="text-gray-500 w-6 h-6 cursor-pointer" />
                </Menu.Button>
                <Transition
                  as={React.Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 w-40 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {campaign.status === 'paused' ? (
                        <Menu.Item>
                          <button
                            onClick={() => handleCampaignAction('play', index)}
                            className="group flex items-center px-4 py-2 text-sm text-gray-700 w-full"
                          >
                            Play
                          </button>
                        </Menu.Item>
                      ) : (
                        <Menu.Item>
                          <button
                            onClick={() => handleCampaignAction('pause', index)}
                            className="group flex items-center px-4 py-2 text-sm text-gray-700 w-full"
                          >
                            Pause
                          </button>
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        <button
                          onClick={() => handleCampaignAction('delete', index)}
                          className="group flex items-center px-4 py-2 text-sm text-gray-700 w-full"
                        >
                          Delete
                        </button>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          ))}
      </div>

      {/* Modal for creating campaigns */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-1/3 relative">
            <button
              className="absolute top-4 right-4 text-gray-600 hover:text-black"
              onClick={closeModal}
            >
              Close
            </button>
            <h2 className="text-xl font-bold mb-4">Create Campaign</h2>
            <input
              type="text"
              value={newCampaignName}
              onChange={(e) => setNewCampaignName(e.target.value)}
              placeholder="Enter campaign name"
              className="border border-gray-300 p-2 rounded-md w-full mb-4"
            />
            <select
              value={campaignType}
              onChange={(e) => setCampaignType(e.target.value)}
              className="border border-gray-300 p-2 rounded-md w-full mb-4"
            >
              <option value="">Select campaign type</option>
              <option value="AI Campaign">AI Campaign</option>
              <option value="Recorded Voice Campaign">Recorded Voice Campaign</option>
            </select>
            <select
              value={selectedGroup}
              onChange={(e) => setSelectedGroup(e.target.value)}
              className="border border-gray-300 p-2 rounded-md w-full mb-4"
            >
              <option value="">Select a group</option>
              {groups.map((group, index) => (
                <option key={index} value={group}>
                  {group}
                </option>
              ))}
            </select>
            <button
              onClick={createCampaign}
              className="bg-black text-white px-4 py-2 rounded-md w-full"
            >
              Create Campaign
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Campaigns;
