import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SummaryCard from './SummaryCard';
import LeadTable from './LeadTable';
import { Dialog, Transition } from '@headlessui/react';
import { FiPlus } from 'react-icons/fi';
import { db, auth } from '../firebase'; // Import Firebase services
import {
  addDoc,
  collection,
  query,
  where,
  onSnapshot,
} from 'firebase/firestore';

const Dashboard = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [isCampaignModalOpen, setIsCampaignModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [newCampaignName, setNewCampaignName] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [campaignType, setCampaignType] = useState('');
  const [campaigns, setCampaigns] = useState([]);
  const [groups, setGroups] = useState([]);
  const [leadData, setLeadData] = useState('');
  const [fileData, setFileData] = useState(null);
  const user = auth.currentUser;

  // Fetch groups from Firestore
  useEffect(() => {
    if (user) {
      const groupsRef = collection(db, 'groups');
      const q = query(groupsRef, where('uid', '==', user.uid));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        if (snapshot.empty) {
          // If no groups, create a default group
          createDefaultGroup();
        } else {
          const groupsData = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setGroups(groupsData);
        }
      });
      return () => unsubscribe();
    }
  }, [user]);

  // Function to create a default group
  const createDefaultGroup = async () => {
    try {
      const defaultGroup = {
        name: 'Default',
        uid: user.uid,
      };
      await addDoc(collection(db, 'groups'), defaultGroup);
    } catch (error) {
      console.error('Error creating default group:', error);
    }
  };

  // Open modal to create a new campaign
  const openCampaignModal = () => {
    setIsCampaignModalOpen(true);
  };

  // Close modal for campaign creation
  const closeCampaignModal = () => {
    setIsCampaignModalOpen(false);
    setNewCampaignName('');
    setSelectedGroup('');
    setCampaignType('');
  };

  // Handle campaign creation
  const createCampaign = () => {
    if (newCampaignName && selectedGroup && campaignType) {
      const newCampaign = {
        name: newCampaignName,
        group: selectedGroup,
        type: campaignType,
      };
      setCampaigns([...campaigns, newCampaign]);
      closeCampaignModal();
    } else {
      alert('Please enter all campaign details.');
    }
  };

  // Open modal for importing leads
  const openImportModal = () => {
    setIsImportModalOpen(true);
  };

  // Close modal for importing leads
  const closeImportModal = () => {
    setIsImportModalOpen(false);
    setLeadData('');
    setFileData(null);
  };

  // Handle importing leads from CSV format (either pasted or uploaded file)
  const handleImportLeads = async () => {
    if (!selectedGroup) {
      alert('Please select a group.');
      return;
    }

    if (!user) {
      alert('No authenticated user found.');
      return;
    }

    let parsedLeads = [];
    const existingLeadsSnapshot = await query(collection(db, 'leads'), where('uid', '==', user.uid));

    // Store existing lead phone numbers for duplicate checking
    const existingLeads = new Set();
    const existingLeadsDocs = await onSnapshot(existingLeadsSnapshot, (snapshot) => {
      snapshot.forEach(doc => {
        existingLeads.add(doc.data().phone);
      });
    });

    // Process pasted leads data
    if (leadData) {
      let lines = leadData.split('\n').filter((line) => line.trim() !== '');

      // Check if the first line is a header and skip it
      if (lines[0].trim().toLowerCase().includes('client') || lines[0].trim().toLowerCase().includes('name')) {
        lines = lines.slice(1);
      }

      parsedLeads = lines
        .map((line) => {
          const [client, phone] = line.split(',');
          if (client && phone) {
            // Check for duplicates
            if (existingLeads.has(phone.trim())) {
              return null; // Skip duplicates
            }
            return {
              client: client.trim(),
              phone: phone.trim(),
              status: 'New Contact',
              group: selectedGroup,
              uid: user.uid,
              notes: '',
            };
          }
          return null;
        })
        .filter(Boolean); // Remove invalid entries
    }

    // Process uploaded CSV file
    if (fileData) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const csvData = e.target.result;
        const rows = csvData.split('\n').filter((row) => row.trim() !== '');

        // Check if the first line is a header and skip it
        let dataRows = rows;
        if (dataRows[0].trim().toLowerCase().includes('client') || dataRows[0].trim().toLowerCase().includes('name')) {
          dataRows = dataRows.slice(1);
        }

        const leadsFromFile = dataRows
          .map((row) => {
            const [client, phone] = row.split(',');
            if (client && phone) {
              // Check for duplicates
              if (existingLeads.has(phone.trim())) {
                return null; // Skip duplicates
              }
              return {
                client: client.trim(),
                phone: phone.trim(),
                status: 'New Contact',
                group: selectedGroup,
                uid: user.uid,
                notes: '',
              };
            }
            return null;
          })
          .filter(Boolean);

        parsedLeads = [...parsedLeads, ...leadsFromFile];

        // Save all the leads to Firestore
        try {
          const leadsCollection = collection(db, 'leads');
          for (const lead of parsedLeads) {
            await addDoc(leadsCollection, lead);
          }
          alert(`Leads imported to ${selectedGroup}`);
        } catch (error) {
          console.error('Error adding leads: ', error);
          alert('Failed to import some leads. Check console for details.');
        }

        // Clear input fields and close the modal
        setLeadData('');
        setFileData(null);
        setIsImportModalOpen(false);
      };
      reader.readAsText(fileData);
    } else if (parsedLeads.length > 0) {
      // Save leads parsed from text input directly
      try {
        const leadsCollection = collection(db, 'leads');
        for (const lead of parsedLeads) {
          await addDoc(leadsCollection, lead);
        }
        alert(`Leads imported to ${selectedGroup}`);
      } catch (error) {
        console.error('Error adding leads: ', error);
        alert('Failed to import some leads. Check console for details.');
      }
      // Clear input fields and close the modal
      setLeadData('');
      setFileData(null);
      setIsImportModalOpen(false);
    } else {
      alert('No leads to import.');
    }
  };

  // Handle file input
  const handleFileChange = (e) => {
    setFileData(e.target.files[0]);
  };

  return (
    <div className="p-6">
      {/* Date Picker and Buttons */}
      <div className="flex justify-between items-center mb-6">
        <div className="hidden sm:block"> {/* Hide on mobile */}
          <DatePicker
            selected={startDate}
            onChange={(dates) => {
              const [start, end] = dates;
              setStartDate(start);
              setEndDate(end);
            }}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            placeholderText="Select date range"
            className="border border-gray-300 p-2 rounded-md"
          />
        </div>

        <div className="flex space-x-2 flex-wrap sm:flex-nowrap"> {/* Ensure buttons wrap if necessary */}
          <button
            onClick={openImportModal}
            className="border border-black text-black px-4 py-2 rounded-md"
          >
            Import Leads
          </button>
          <button
            onClick={openCampaignModal}
            className="bg-black text-white px-4 py-2 rounded-md flex items-center space-x-2"
          >
            <FiPlus />
            <span>Create a Campaign</span>
          </button>
        </div>
      </div>

      {/* Summary Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        <SummaryCard title="Total Calls" value="320" icon="call" />
        <SummaryCard title="Conversion Rate" value="320" icon="conversion" />
        <SummaryCard title="Answered Calls" value="320" icon="answered" />
        <SummaryCard title="Missed Calls" value="320" icon="missed" />
      </div>

      {/* Lead Table */}
      <LeadTable groups={groups} />

      {/* Campaign List */}
      {campaigns.length > 0 && (
        <div className="mt-6">
          <h2 className="text-xl font-bold mb-4">Created Campaigns</h2>
          <ul>
            {campaigns.map((campaign, index) => (
              <li key={index} className="mb-2">
                {campaign.name} - {campaign.group} ({campaign.type})
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Modal for creating campaigns */}
      <Transition appear show={isCampaignModalOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeCampaignModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Create Campaign
                  </Dialog.Title>
                  <div className="mt-2">
                    <input
                      type="text"
                      value={newCampaignName}
                      onChange={(e) => setNewCampaignName(e.target.value)}
                      placeholder="Enter campaign name"
                      className="border border-gray-300 p-2 rounded-md w-full mb-4"
                    />
                    <select
                      value={campaignType}
                      onChange={(e) => setCampaignType(e.target.value)}
                      className="border border-gray-300 p-2 rounded-md w-full mb-4"
                    >
                      <option value="">Select campaign type</option>
                      <option value="AI Campaign">AI Campaign</option>
                      <option value="Recorded Voice Campaign">
                        Recorded Voice Campaign
                      </option>
                    </select>
                    <select
                      value={selectedGroup}
                      onChange={(e) => setSelectedGroup(e.target.value)}
                      className="border border-gray-300 p-2 rounded-md w-full mb-4"
                    >
                      <option value="">Select a group</option>
                      {groups.map((group) => (
                        <option key={group.id} value={group.name}>
                          {group.name}
                        </option>
                      ))}
                    </select>
                    <button
                      onClick={createCampaign}
                      className="bg-black text-white px-4 py-2 rounded-md w-full"
                    >
                      Create Campaign
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Modal Popup for Importing Leads */}
      <Transition appear show={isImportModalOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeImportModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Import Leads
                  </Dialog.Title>
                  <div className="mt-2">
                    <select
                      value={selectedGroup}
                      onChange={(e) => setSelectedGroup(e.target.value)}
                      className="border border-gray-300 p-2 rounded-md w-full mb-4"
                    >
                      <option value="">Select a group</option>
                      {groups.map((group) => (
                        <option key={group.id} value={group.name}>
                          {group.name}
                        </option>
                      ))}
                    </select>
                    <textarea
                      placeholder="Paste leads here in CSV format: client,phone"
                      value={leadData}
                      onChange={(e) => setLeadData(e.target.value)}
                      className="border border-gray-300 p-2 rounded-md w-full h-32"
                    />
                    <input
                      type="file"
                      accept=".csv"
                      onChange={handleFileChange}
                      className="border border-gray-300 p-2 rounded-md w-full mt-2"
                    />
                  </div>
                  <div className="mt-4 flex justify-between">
                    <button
                      className="bg-black text-white px-4 py-2 rounded-md"
                      onClick={handleImportLeads}
                    >
                      Import Leads
                    </button>
                    <button
                      className="bg-gray-300 px-4 py-2 rounded-md"
                      onClick={closeImportModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Dashboard;
