import React, { useState, useEffect } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import { Menu, Transition } from '@headlessui/react';
import { db, auth } from '../firebase';
import {
  collection,
  query,
  where,
  onSnapshot,
  deleteDoc,
  doc,
  updateDoc,
} from 'firebase/firestore';

const LeadTable = ({ groups = [], searchQuery = '', selectedGroup = '' }) => {
  const [leads, setLeads] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [notes, setNotes] = useState('');

  useEffect(() => {
    const user = auth.currentUser;

    if (user) {
      const leadsRef = collection(db, 'leads');
      let q = query(leadsRef, where('uid', '==', user.uid));

      if (selectedGroup) {
        q = query(
          leadsRef,
          where('uid', '==', user.uid),
          where('group', '==', selectedGroup)
        );
      }

      const unsubscribeLeads = onSnapshot(q, (snapshot) => {
        const leadsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setLeads(leadsData);
      });
      return () => unsubscribeLeads();
    } else {
      setLeads([]);
    }
  }, [selectedGroup]);

  const handleActionClick = async (action, lead) => {
    if (action === 'Open') {
      setSelectedLead(lead);
      setIsModalOpen(true);
    } else if (action === 'Delete') {
      setSelectedLead(lead);
      setIsDeleteModalOpen(true);
    } else if (action === 'Add Notes') {
      setSelectedLead(lead);
      setNotes(lead.notes || '');
      setIsNotesModalOpen(true);
    }
  };

  const confirmDelete = async () => {
    if (selectedLead) {
      await deleteDoc(doc(db, 'leads', selectedLead.id));
      setIsDeleteModalOpen(false);
      setSelectedLead(null);
    } else if (selectedLeads.length > 0) {
      for (const lead of selectedLeads) {
        await deleteDoc(doc(db, 'leads', lead.id));
      }
      setIsDeleteModalOpen(false);
      setSelectedLeads([]);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedLead(null);
  };

  const saveNotes = async () => {
    try {
      await updateDoc(doc(db, 'leads', selectedLead.id), {
        notes: notes,
      });
      setIsNotesModalOpen(false);
      setSelectedLead(null);
      setNotes('');
    } catch (error) {
      console.error('Error saving notes:', error);
    }
  };

  const filteredLeads = leads.filter((lead) => {
    return lead.client.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const handleSelectLead = (lead) => {
    const isSelected = selectedLeads.some((l) => l.id === lead.id);
    if (isSelected) {
      setSelectedLeads(selectedLeads.filter((l) => l.id !== lead.id));
    } else {
      setSelectedLeads([...selectedLeads, lead]);
    }
  };

  const isLeadSelected = (lead) => {
    return selectedLeads.some((l) => l.id === lead.id);
  };

  return (
    <div className="relative">
      {selectedLeads.length > 0 && (
        <div className="mb-4">
          <button
            onClick={() => setIsDeleteModalOpen(true)}
            className="bg-red-500 text-white px-4 py-2 rounded-md"
          >
            Delete Selected ({selectedLeads.length})
          </button>
        </div>
      )}

      {/* Table - with horizontal scrolling and fixed height */}
      <div className="bg-white p-4 rounded-lg border border-gray-300 overflow-x-auto">
        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <table className="w-full table-auto">
            <thead>
              <tr>
                <th className="p-2" style={{ whiteSpace: 'nowrap' }}>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedLeads(filteredLeads);
                      } else {
                        setSelectedLeads([]);
                      }
                    }}
                    checked={
                      selectedLeads.length > 0 &&
                      selectedLeads.length === filteredLeads.length
                    }
                  />
                </th>
                <th className="text-left p-2" style={{ whiteSpace: 'nowrap' }}>Client</th>
                <th className="text-left p-2" style={{ whiteSpace: 'nowrap' }}>Phone Number</th>
                <th className="text-left p-2" style={{ whiteSpace: 'nowrap' }}>Status</th>
                <th className="text-left p-2" style={{ whiteSpace: 'nowrap' }}>Voice Recording</th>
                <th className="text-left p-2" style={{ whiteSpace: 'nowrap' }}>Group</th>
                <th className="text-left p-2" style={{ whiteSpace: 'nowrap' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredLeads.length > 0 ? (
                filteredLeads.map((lead) => (
                  <tr key={lead.id} className="hover:bg-gray-300 cursor-pointer" style={{ whiteSpace: 'nowrap' }}>
                    <td className="p-2">
                      <input
                        type="checkbox"
                        checked={isLeadSelected(lead)}
                        onChange={() => handleSelectLead(lead)}
                      />
                    </td>
                    <td className="p-2" onClick={() => handleActionClick('Open', lead)}>
                      {lead.client}
                    </td>
                    <td className="p-2" onClick={() => handleActionClick('Open', lead)}>
                      {lead.phone}
                    </td>
                    <td className="p-2">
                      <span
                        className="px-2 py-1 rounded-full text-sm"
                        style={{
                          backgroundColor: '#e0f2fe',
                          color: '#0369a1',
                        }}
                      >
                        {lead.status}
                      </span>
                    </td>
                    <td className="p-2">
                      <audio
                        controls
                        className="w-full"
                        style={{ backgroundColor: 'transparent' }} // Make background transparent
                      >
                        <source src={lead.audioUrl} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    </td>
                    <td className="p-2">{lead.group}</td>
                    <td className="p-2">
                      <Menu as="div" className="relative">
                        <Menu.Button
                          onClick={(e) => e.stopPropagation()}
                          className="flex items-center focus:outline-none"
                        >
                          <FiMoreVertical className="text-gray-500 w-6 h-6 cursor-pointer hover:text-gray-700" />
                        </Menu.Button>
                        <Transition
                          as={React.Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 mt-2 w-40 bg-white shadow-lg border border-gray-200 rounded-md focus:outline-none z-10">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={() => handleActionClick('Open', lead)}
                                  className={`w-full px-4 py-2 text-left text-sm ${active ? 'bg-gray-100' : ''}`}
                                >
                                  Open
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={() => handleActionClick('Delete', lead)}
                                  className={`w-full px-4 py-2 text-left text-sm ${active ? 'bg-gray-100' : ''}`}
                                >
                                  Delete
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={() => handleActionClick('Add Notes', lead)}
                                  className={`w-full px-4 py-2 text-left text-sm ${active ? 'bg-gray-100' : ''}`}
                                >
                                  Add Notes
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className="text-center p-2">
                    No leads found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal for Lead Details */}
      {isModalOpen && selectedLead && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg border border-gray-300 w-11/12 md:w-1/2 lg:w-1/3 relative">
            <button
              className="absolute top-4 right-4 text-gray-600 hover:text-black focus:outline-none"
              onClick={closeModal}
            >
              Close
            </button>
            <h2 className="text-xl font-bold mb-4">Client Details</h2>
            <div className="space-y-2">
              <p>
                <strong>Client:</strong> {selectedLead.client}
              </p>
              <p>
                <strong>Phone:</strong> {selectedLead.phone}
              </p>
              <p>
                <strong>Status:</strong> {selectedLead.status}
              </p>
              <p>
                <strong>Group:</strong> {selectedLead.group}
              </p>
              <p>
                <strong>Notes:</strong>{' '}
                {selectedLead.notes || 'No notes added.'}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Modal for Adding/Editing Notes */}
      {isNotesModalOpen && selectedLead && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg border border-gray-300 w-80 relative">
            <h2 className="text-xl font-bold mb-4">Add Notes</h2>
            <textarea
              className="w-full h-32 border border-gray-300 p-2 rounded-md"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            ></textarea>
            <div className="mt-4 flex justify-end space-x-2">
              <button
                className="bg-gray-300 px-4 py-2 rounded-md"
                onClick={() => {
                  setIsNotesModalOpen(false);
                  setSelectedLead(null);
                  setNotes('');
                }}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md"
                onClick={saveNotes}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Confirmation Modal for Delete */}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg border border-gray-300 w-80 relative">
            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
            <p>
              {selectedLead
                ? `Are you sure you want to delete ${selectedLead.client}?`
                : `Are you sure you want to delete ${selectedLeads.length} leads?`}
            </p>
            <div className="mt-4 flex justify-end space-x-2">
              <button
                className="bg-gray-300 px-4 py-2 rounded-md"
                onClick={() => {
                  setIsDeleteModalOpen(false);
                  setSelectedLead(null);
                }}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-md"
                onClick={confirmDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeadTable;
