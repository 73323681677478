import React, { useState, useEffect } from 'react';
import LeadTable from './LeadTable';
import { Dialog, Transition } from '@headlessui/react';
import { db, auth } from '../firebase';
import {
  addDoc,
  collection,
  query,
  where,
  onSnapshot,
} from 'firebase/firestore';

const Contacts = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [groups, setGroups] = useState([]);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [leadData, setLeadData] = useState('');
  const [fileData, setFileData] = useState(null);

  const user = auth.currentUser;

  // Fetch groups from Firestore
  useEffect(() => {
    if (user) {
      const groupsRef = collection(db, 'groups');
      const q = query(groupsRef, where('uid', '==', user.uid));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        if (snapshot.empty) {
          // If no groups, create a default group
          createDefaultGroup();
        } else {
          const groupsData = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setGroups(groupsData);
        }
      });
      return () => unsubscribe();
    }
  }, [user]);

  // Function to create a default group
  const createDefaultGroup = async () => {
    try {
      const defaultGroup = {
        name: 'Default',
        uid: user.uid,
      };
      await addDoc(collection(db, 'groups'), defaultGroup);
    } catch (error) {
      console.error('Error creating default group:', error);
    }
  };

  // Handle creating a new group
  const handleCreateGroup = async () => {
    if (newGroupName) {
      try {
        const group = {
          name: newGroupName,
          uid: user.uid,
        };
        await addDoc(collection(db, 'groups'), group);
        setNewGroupName('');
        setIsGroupModalOpen(false);
      } catch (error) {
        console.error('Error creating group:', error);
      }
    }
  };

  // Handle importing leads from CSV format (either pasted or uploaded file)
  const handleImportLeads = async () => {
    if (!selectedGroup) {
      alert('Please select a group.');
      return;
    }

    if (!user) {
      alert('No authenticated user found.');
      return;
    }

    let parsedLeads = [];

    // Process pasted leads data
    if (leadData) {
      let lines = leadData.split('\n').filter((line) => line.trim() !== '');

      // Check if the first line is a header and skip it
      if (
        lines[0]
          .trim()
          .toLowerCase()
          .replace(/\s/g, '') === 'client,phone' ||
        lines[0]
          .trim()
          .toLowerCase()
          .replace(/\s/g, '') === 'name,phone'
      ) {
        lines = lines.slice(1);
      }

      parsedLeads = lines
        .map((line) => {
          const [client, phone] = line.split(',');
          if (client && phone) {
            return {
              client: client.trim(),
              phone: phone.trim(),
              status: 'New Contact', // Default status for all new leads
              group: selectedGroup,
              uid: user.uid, // Add user's UID to the lead
              notes: '', // Initialize notes as an empty string
            };
          }
          return null;
        })
        .filter(Boolean); // Remove invalid entries
    }

    // Process uploaded CSV file
    if (fileData) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const csvData = e.target.result;
        const rows = csvData.split('\n').filter((row) => row.trim() !== '');

        // Check if the first line is a header and skip it
        let dataRows = rows;
        if (
          rows[0]
            .trim()
            .toLowerCase()
            .replace(/\s/g, '') === 'client,phone' ||
          rows[0]
            .trim()
            .toLowerCase()
            .replace(/\s/g, '') === 'name,phone'
        ) {
          dataRows = rows.slice(1);
        }

        const leadsFromFile = dataRows
          .map((row) => {
            const [client, phone] = row.split(',');
            if (client && phone) {
              return {
                client: client.trim(),
                phone: phone.trim(),
                status: 'New Contact',
                group: selectedGroup,
                uid: user.uid, // Add user's UID to the lead
                notes: '', // Initialize notes as an empty string
              };
            }
            return null;
          })
          .filter(Boolean);

        parsedLeads = [...parsedLeads, ...leadsFromFile];

        // Save all the leads to Firestore
        try {
          const leadsCollection = collection(db, 'leads');
          for (const lead of parsedLeads) {
            await addDoc(leadsCollection, lead);
          }
          alert(`Leads imported to ${selectedGroup}`);
        } catch (error) {
          console.error('Error adding leads: ', error);
          alert('Failed to import some leads. Check console for details.');
        }

        // Clear input fields and close the modal
        setLeadData('');
        setFileData(null);
        setIsImportModalOpen(false);
      };
      reader.readAsText(fileData);
    } else if (parsedLeads.length > 0) {
      // Save leads parsed from text input directly
      try {
        const leadsCollection = collection(db, 'leads');
        for (const lead of parsedLeads) {
          await addDoc(leadsCollection, lead);
        }
        alert(`Leads imported to ${selectedGroup}`);
      } catch (error) {
        console.error('Error adding leads: ', error);
        alert('Failed to import some leads. Check console for details.');
      }
      // Clear input fields and close the modal
      setLeadData('');
      setFileData(null);
      setIsImportModalOpen(false);
    } else {
      alert('No leads to import.');
    }
  };

  // Handle file input
  const handleFileChange = (e) => {
    setFileData(e.target.files[0]);
  };

  return (
    <div className="p-6">
      {/* Search, Filters, and Buttons */}
      <div className="flex justify-between items-center mb-6 space-x-4">
        {/* Group Filter/Creation */}
        <div className="hidden sm:flex items-center space-x-2"> {/* Hidden on mobile */}
          <button
            onClick={() => setIsGroupModalOpen(true)}
            className="border border-gray-400 text-black px-4 py-2 rounded-md"
          >
            + Create Group
          </button>
          <select
            value={selectedGroup}
            onChange={(e) => setSelectedGroup(e.target.value)}
            className="border border-gray-300 p-2 rounded-md"
          >
            <option value="">All Groups</option>
            {groups.map((group) => (
              <option key={group.id} value={group.name}>
                {group.name}
              </option>
            ))}
          </select>
        </div>

        {/* Search Input (Fixed width, hidden on mobile) */}
        <div className="hidden sm:flex items-center">
          <input
            type="text"
            placeholder="Search contacts..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border border-gray-300 p-2 rounded-md w-64" // Fixed width
          />
        </div>

        {/* Action Buttons (Right-aligned, visible on all screens) */}
        <div className="flex space-x-2">
          <button
            onClick={() => setIsImportModalOpen(true)}
            className="bg-black text-white px-4 py-2 rounded-md"
          >
            Import Leads
          </button>
          <button
            onClick={() => setIsGroupModalOpen(true)}
            className="border border-gray-400 text-black px-4 py-2 rounded-md sm:hidden"
          >
            + Create Group
          </button> {/* Show Create Group on mobile */}
        </div>
      </div>

      {/* Lead Table */}
      <LeadTable
        searchQuery={searchQuery}
        selectedGroup={selectedGroup}
        groups={groups}
      />

      {/* Modal Popup for Creating Groups */}
      <Transition appear show={isGroupModalOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsGroupModalOpen(false)}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium text-gray-900"
                  >
                    Create New Group
                  </Dialog.Title>
                  <div className="mt-2">
                    <input
                      type="text"
                      placeholder="Group name"
                      value={newGroupName}
                      onChange={(e) => setNewGroupName(e.target.value)}
                      className="border border-gray-300 p-2 rounded-md w-full"
                    />
                  </div>
                  <div className="mt-4 flex justify-between">
                    <button
                      className="bg-black text-white px-4 py-2 rounded-md"
                      onClick={handleCreateGroup}
                    >
                      Create Group
                    </button>
                    <button
                      className="bg-gray-300 px-4 py-2 rounded-md"
                      onClick={() => setIsGroupModalOpen(false)}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Modal Popup for Importing Leads */}
      <Transition appear show={isImportModalOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsImportModalOpen(false)}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium text-gray-900"
                  >
                    Import Leads
                  </Dialog.Title>
                  <div className="mt-2">
                    <select
                      value={selectedGroup}
                      onChange={(e) => setSelectedGroup(e.target.value)}
                      className="border border-gray-300 p-2 rounded-md w-full mb-4"
                    >
                      <option value="">Select a group</option>
                      {groups.map((group) => (
                        <option key={group.id} value={group.name}>
                          {group.name}
                        </option>
                      ))}
                    </select>
                    <textarea
                      placeholder="Paste leads here in CSV format: client,phone"
                      value={leadData}
                      onChange={(e) => setLeadData(e.target.value)}
                      className="border border-gray-300 p-2 rounded-md w-full h-32"
                    />
                    <input
                      type="file"
                      accept=".csv"
                      onChange={handleFileChange}
                      className="border border-gray-300 p-2 rounded-md w-full mt-2"
                    />
                  </div>
                  <div className="mt-4 flex justify-between">
                    <button
                      className="bg-black text-white px-4 py-2 rounded-md"
                      onClick={handleImportLeads}
                    >
                      Import Leads
                    </button>
                    <button
                      className="bg-gray-300 px-4 py-2 rounded-md"
                      onClick={() => setIsImportModalOpen(false)}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Contacts;
