import React from 'react';

const Profile = () => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Profile Settings</h1>
      <div className="bg-white shadow-md rounded-lg p-6 max-w-lg mx-auto">
        <form>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              id="name"
              className="border border-gray-300 rounded-md p-2 w-full"
              placeholder="Enter your name"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="border border-gray-300 rounded-md p-2 w-full"
              placeholder="Enter your email"
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 font-semibold mb-2" htmlFor="password">
              Password
            </label>
            <input
              type="password"
              id="password"
              className="border border-gray-300 rounded-md p-2 w-full"
              placeholder="Enter new password"
            />
          </div>

          <button
            type="submit"
            className="bg-black text-white px-4 py-2 rounded-md w-full"
          >
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
};

export default Profile;
