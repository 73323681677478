// src/components/SummaryCard.js
import React from 'react';
import { FiPhoneCall, FiTrendingUp, FiCheckCircle, FiXCircle } from 'react-icons/fi';

const SummaryCard = ({ title, value, icon }) => {
  const icons = {
    call: <FiPhoneCall className="text-gray-500 w-6 h-6" />,
    conversion: <FiTrendingUp className="text-green-500 w-6 h-6" />,
    answered: <FiCheckCircle className="text-blue-500 w-6 h-6" />,
    missed: <FiXCircle className="text-red-500 w-6 h-6" />,
  };

  return (
    <div className="bg-white p-4 rounded-lg border border-gray-300 flex items-center justify-between">
      <div>
        <p className="text-gray-600">{title}</p>
        <p className="text-3xl font-bold text-gray-900">{value}</p>
      </div>
      <div>{icons[icon]}</div>
    </div>
  );
};

export default SummaryCard;
