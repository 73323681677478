import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Dashboard from './components/Dashboard';
import Campaigns from './components/Campaigns';
import Contacts from './components/Contacts';
import CallAutomation from './components/CallAutomation';
import Settings from './components/Settings';
import Profile from './components/Profile';
import Login from './components/Login';
import Register from './components/Register'; // Import Register component
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import './firebase'; // Make sure firebase.js is imported

const App = () => {
  const auth = getAuth();
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true); // Add loading state

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setLoading(false); // Set loading to false after auth state is resolved
    });

    return () => unsubscribe();
  }, [auth]);

  const ProtectedRoute = ({ children }) => {
    if (loading) {
      return <div>Loading...</div>; // Display a loading state while checking authentication
    }
    if (!user) {
      return <Navigate to="/login" replace />;
    }
    return children;
  };

  return (
    <div className="bg-gray-200 min-h-screen flex flex-col">
      {user && <Header />}
      <main className="flex-grow bg-white rounded-t-xl w-full">
        <div className="px-4 py-4 max-w-full mx-auto h-full">
          <Routes>
            {/* Login route */}
            <Route path="/login" element={<Login />} />

            {/* Register route */}
            <Route path="/register" element={<Register />} />

            {/* Protected routes */}
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/campaigns"
              element={
                <ProtectedRoute>
                  <Campaigns />
                </ProtectedRoute>
              }
            />
            <Route
              path="/contacts"
              element={
                <ProtectedRoute>
                  <Contacts />
                </ProtectedRoute>
              }
            />
            <Route
              path="/call-automation"
              element={
                <ProtectedRoute>
                  <CallAutomation />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
      </main>
    </div>
  );
};

export default App;
