import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  FiHome,
  FiSettings,
  FiUsers,
  FiPhoneCall,
  FiClipboard,
  FiBell,
  FiMenu,
  FiUser,
} from 'react-icons/fi';
import NotificationDropdown from './NotificationDropdown';
import ProfileDropdown from './ProfileDropdown';

const Header = () => {
  const location = useLocation();
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const [isProfileOpen, setProfileOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const profileRef = useRef(null);
  const notificationRef = useRef(null);

  const toggleNotification = () => setNotificationOpen(!isNotificationOpen);
  const toggleProfile = () => setProfileOpen(!isProfileOpen);
  const toggleMenu = () => setMenuOpen(!isMenuOpen);

  // Close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setProfileOpen(false);
      }
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setNotificationOpen(false);
      }
      if (!event.target.closest('.hamburger-menu')) {
        setMenuOpen(false); // Close menu when clicking outside
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="bg-gray-200 p-4 flex justify-between items-center">
      {/* Logo */}
      <div className="flex items-center space-x-2">
        <img src={require('../assets/logo.png')} alt="Logo" className="h-8" />
      </div>

      {/* Desktop Navigation */}
      <nav className="hidden lg:flex space-x-10 text-lg">
        <Link
          to="/"
          className={`flex items-center space-x-2 group ${
            location.pathname === '/' ? 'text-black' : 'text-[#868CAA]'
          } hover:text-black`}
        >
          <FiHome
            className={`text-xl ${
              location.pathname === '/' ? 'text-black' : 'text-[#868CAA]'
            } group-hover:text-black`}
          />
          <span className="group-hover:text-black">Dashboard</span>
        </Link>
        <Link
          to="/campaigns"
          className={`flex items-center space-x-2 group ${
            location.pathname === '/campaigns' ? 'text-black' : 'text-[#868CAA]'
          } hover:text-black`}
        >
          <FiClipboard
            className={`text-xl ${
              location.pathname === '/campaigns' ? 'text-black' : 'text-[#868CAA]'
            } group-hover:text-black`}
          />
          <span className="group-hover:text-black">Campaigns</span>
        </Link>
        <Link
          to="/contacts"
          className={`flex items-center space-x-2 group ${
            location.pathname === '/contacts' ? 'text-black' : 'text-[#868CAA]'
          } hover:text-black`}
        >
          <FiUsers
            className={`text-xl ${
              location.pathname === '/contacts' ? 'text-black' : 'text-[#868CAA]'
            } group-hover:text-black`}
          />
          <span className="group-hover:text-black">Contacts</span>
        </Link>
        <Link
          to="/call-automation"
          className={`flex items-center space-x-2 group ${
            location.pathname === '/call-automation' ? 'text-black' : 'text-[#868CAA]'
          } hover:text-black`}
        >
          <FiPhoneCall
            className={`text-xl ${
              location.pathname === '/call-automation' ? 'text-black' : 'text-[#868CAA]'
            } group-hover:text-black`}
          />
          <span className="group-hover:text-black">Call Automation</span>
        </Link>
        <Link
          to="/settings"
          className={`flex items-center space-x-2 group ${
            location.pathname === '/settings' ? 'text-black' : 'text-[#868CAA]'
          } hover:text-black`}
        >
          <FiSettings
            className={`text-xl ${
              location.pathname === '/settings' ? 'text-black' : 'text-[#868CAA]'
            } group-hover:text-black`}
          />
          <span className="group-hover:text-black">Settings</span>
        </Link>
      </nav>

      {/* Right Section */}
      <div className="flex items-center space-x-6 relative">
        {/* Notification Icon */}
        <div className="relative" ref={notificationRef}>
          <FiBell
            className="text-gray-700 cursor-pointer hover:text-black text-xl"
            onClick={toggleNotification}
          />
          {isNotificationOpen && <NotificationDropdown />}
        </div>

        {/* Profile Icon */}
        <div
          className="relative flex items-center cursor-pointer"
          ref={profileRef}
          onClick={toggleProfile}
        >
          <FiUser className="text-gray-700 hover:text-black text-xl" />
          {isProfileOpen && <ProfileDropdown />}
        </div>

        {/* Hamburger for Mobile */}
        <div className="lg:hidden flex items-center hamburger-menu">
          <FiMenu className="text-2xl cursor-pointer" onClick={toggleMenu} />
          {isMenuOpen && (
            <div className="absolute right-0 top-12 w-48 bg-white border border-gray-300 rounded-md shadow-lg z-10">
              <Link
                to="/"
                className={`flex items-center px-4 py-2 group ${
                  location.pathname === '/' ? 'text-black' : 'text-gray-700'
                } hover:bg-gray-100 hover:text-black`}
                onClick={() => setMenuOpen(false)}
              >
                <FiHome
                  className={`mr-2 ${
                    location.pathname === '/' ? 'text-black' : 'text-gray-700'
                  } group-hover:text-black`}
                />{' '}
                <span className="group-hover:text-black">Dashboard</span>
              </Link>
              <Link
                to="/campaigns"
                className={`flex items-center px-4 py-2 group ${
                  location.pathname === '/campaigns' ? 'text-black' : 'text-gray-700'
                } hover:bg-gray-100 hover:text-black`}
                onClick={() => setMenuOpen(false)}
              >
                <FiClipboard
                  className={`mr-2 ${
                    location.pathname === '/campaigns' ? 'text-black' : 'text-gray-700'
                  } group-hover:text-black`}
                />{' '}
                <span className="group-hover:text-black">Campaigns</span>
              </Link>
              <Link
                to="/contacts"
                className={`flex items-center px-4 py-2 group ${
                  location.pathname === '/contacts' ? 'text-black' : 'text-gray-700'
                } hover:bg-gray-100 hover:text-black`}
                onClick={() => setMenuOpen(false)}
              >
                <FiUsers
                  className={`mr-2 ${
                    location.pathname === '/contacts' ? 'text-black' : 'text-gray-700'
                  } group-hover:text-black`}
                />{' '}
                <span className="group-hover:text-black">Contacts</span>
              </Link>
              <Link
                to="/call-automation"
                className={`flex items-center px-4 py-2 group ${
                  location.pathname === '/call-automation' ? 'text-black' : 'text-gray-700'
                } hover:bg-gray-100 hover:text-black`}
                onClick={() => setMenuOpen(false)}
              >
                <FiPhoneCall
                  className={`mr-2 ${
                    location.pathname === '/call-automation' ? 'text-black' : 'text-gray-700'
                  } group-hover:text-black`}
                />{' '}
                <span className="group-hover:text-black">Call Automation</span>
              </Link>
              <Link
                to="/settings"
                className={`flex items-center px-4 py-2 group ${
                  location.pathname === '/settings' ? 'text-black' : 'text-gray-700'
                } hover:bg-gray-100 hover:text-black`}
                onClick={() => setMenuOpen(false)}
              >
                <FiSettings
                  className={`mr-2 ${
                    location.pathname === '/settings' ? 'text-black' : 'text-gray-700'
                  } group-hover:text-black`}
                />{' '}
                <span className="group-hover:text-black">Settings</span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
