import React from 'react';
import { FiMail, FiAlertCircle, FiServer } from 'react-icons/fi';

const NotificationDropdown = () => {
  return (
    <div className="absolute right-0 mt-2 w-64 bg-white border border-gray-300 rounded-md shadow-lg z-50">
      <div className="p-4">
        <h4 className="font-bold text-lg mb-2">Notifications</h4>
        <ul>
          <li className="flex items-center mb-2">
            <FiMail className="mr-2 text-gray-600" /> You have 2 new messages
          </li>
          <li className="flex items-center mb-2">
            <FiAlertCircle className="mr-2 text-yellow-500" /> Campaign updated successfully
          </li>
          <li className="flex items-center mb-2">
            <FiServer className="mr-2 text-red-500" /> Server maintenance scheduled
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NotificationDropdown;
